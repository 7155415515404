@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .bg-black-custom{
    background-color: #3575ad;
  }
  .bg-black-custom2{
    background-color: #e72ac6;
  }

  .btn-custom {

  }

  .custom-size1{
    width: 20px !important;
    height: 20px !important;
  }
}
